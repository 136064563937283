import React from 'react'

import Layout from 'layouts/Base'
import SEO from 'components/shared/SEO'
import ProductListing from 'components/ProductListing'

export default ({ data, location }) => {
  return (
    <>
      <SEO title="Pots for your plants"/>
      <ProductListing type="pot" title="All Pots"/>
    </>
  )
}