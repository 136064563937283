import React, { useContext } from 'react'
import { InstantSearch, Pagination } from 'react-instantsearch-dom'
import styled from '@emotion/styled'

import ItemHits from './ItemHits'
import { ProductsContext } from 'context/ProductsProvider'
import ProductListingItem from './ProductListingItem'

const ResultHits = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
  grid-template-rows: auto;
  margin-bottom: 2rem;
  gap: 1rem 1rem;
`

export default ({type}) => {
  const { listProducts } = useContext(ProductsContext)
  const products = listProducts(type)
  return (
  	<ResultHits>
	    {/*<ItemHits />
	    <Pagination showLast={true}/>*/}
	    {products.map((product,i) => (
	        <ProductListingItem key={i} product={product} />
	    ))}
  	</ResultHits>
	)
}
