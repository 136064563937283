import React from 'react'
import { connectHits, connectStateResults } from 'react-instantsearch-dom'
import styled from '@emotion/styled'

import ProductListingItem from './ProductListingItem'


const ResultHits = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
  grid-template-rows: auto;
  margin-bottom: 2rem;
  gap: 1rem 1rem;
`

const NoResults = styled('div')`
  margin: 50px 0
`

const Hits = connectHits(({ hits, onHoverItem, onLeaveHoverItem }) => {
  return hits.map(hit => (
    <ProductListingItem key={hit.objectID} product={hit} />
  ))
})

export default connectStateResults(
  ({ searchResults, onHoverItem, onLeaveHoverItem }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      <ResultHits>
        <Hits onHoverItem={onHoverItem} onLeaveHoverItem={onLeaveHoverItem} />
      </ResultHits>
    ) : searchResults && searchResults.nbHits == 0 ? (
      <div className="no-results-container">
        <NoResults>
          <h4>Sorry, we couldn't find anything.</h4>
          <p>Try changing your refinement to display more results.</p>
        </NoResults>
      </div>
    ) : (
      // add loading icon
      <div className="no-results-container">
        <div className="no-results-subcontainer" />
      </div>
    )
)
