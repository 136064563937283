import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { InstantSearch, ScrollTo } from 'react-instantsearch-dom'

import Results from './Results'
import ProductListingHeader from './ProductListingHeader'
import ProductListingItem from './ProductListingItem'
import { RoomsSection } from 'components/HomePage'
import { breakpoints, spacing } from '../../utils/styles'

const ProductListing = ({type,title}) => (
    <Wrapper>
      <ProductListingHeader title={title}/>
      <ProductListingContainer>
        <Results type={type}/>
       
      </ProductListingContainer>
      <RoomsSection/>
    </Wrapper>
)

const ProductListingContainer = styled(`div`)`
 display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing['2xl']}px;
  }
`

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export default ProductListing
