import React from 'react'

import styles from './TopBanner.module.scss'

export default ({title}) => (
	<div className={styles.bannerContainer}>
		<div className={styles.bckgnd}/>
		<div className={styles.content}>
			<h2 className={styles.header}>{title}</h2>
		</div>
	</div>
)